
import dayjs from "@/plugins/dayjs";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";

import PandaMobileTable from "@/components/mobile-custom/panda-table.vue";

import PandaMobileTWFContainer from "@/components/mobile-custom/panda-table-with-filter-container.vue";

import { DayDataStatisticAPI } from "@/common/api/dataWholeBoard/dayDataStatistic";
import { excuteDownloadExport2Excel } from "@/common/xlsx";

function getCurrentMonthDate() {
  const currentDay = (dayjs() as any).format("YYYY-MM-DD");

  const curMonthStartDay = (dayjs().startOf("month") as any).format("YYYY-MM-DD");


  return [curMonthStartDay, currentDay];
}

function getLastMonthDate() {
  const lastEndDay = (dayjs().startOf("month") as any).format("YYYY-MM-DD");

  const lastStartDay = (
    dayjs().subtract(1, "month").endOf("month") as any
  ).format("YYYY-MM-DD");

  return [lastStartDay, lastEndDay];
}

// console.log(getCurrentMonthDate(), 'getCurrentMonthDate')
export const pageApiList = getPageAPIs(DayDataStatisticAPI);

@Component({
  components: {
    PandaMobileTable,
    PandaMobileTWFContainer,
  },
  filters: {
    // getTodayTotalIncome(row: any) {
    // }
  },
})
export default class MerchantsAudit extends Vue {
  /** 移动端相关属性 方法 */
  private allMobileChooseFields: any[] = [
    {
      label: "日期",
      field: "date",
    },
    {
      label: "GMV",
      field: "gmv",
    },
    {
      label: "新增用户",
      field: "user_num",
    },
    {
      label: "日活",
      field: "activity",
    },
    {
      label: "APP日活",
      field: "app_activity",
    },
    {
      label: "IOS注册",
      field: "ios_register",
    },
    {
      label: "Android注册",
      field: "android_register",
    },
    {
      label: "平台抽成",
      field: "platform_income",
    },
    {
      label: "单店卡充值",
      field: "shop_member_recharge",
    },
    {
      label: "单店卡消耗",
      field: "shop_member_consume",
    },
    {
      label: "全国会员充值",
      field: "nationwide_member_recharge",
    },
    {
      label: "全国会员消耗",
      field: "nationwide_member_consume",
    },
    {
      label: "美团券",
      field: "meituan",
    },
    {
      label: "抖音券",
      field: "douyin",
    },
    {
      label: "快手券",
      field: "kuaishou",
    },
    {
      label: "优惠券",
      field: "changda",
    },
    {
      label: "体育券",
      field: "sport",
    },
    {
      label: "微信/支付宝",
      field: "wechat_alipay",
    },
    {
      label: "售卖柜",
      field: "sales_counter",
    },
    {
      label: "收银台商品",
      field: "cashier_product_sales",
    },
    {
      label: '收银台教练',
      field: 'cashier_assistant'
    },
    {
      label: "助教",
      field: "user_assistant",
    },
    {
      label: "开台时长（h）",
      field: "open_duration",
    },
    {
      label: "免费开台时长（h）",
      field: "free_open_duration",
    },
    {
      label: "订单量",
      field: "order_num",
    },
    {
      label: "报名费",
      field: "billiard_entry_fee",
    },
   
  ];
  /** end 移动端相关属性 方法 */

  private dayDataStatisticApi: any;

  constructor() {
    super();
    this.dayDataStatisticApi = new DayDataStatisticAPI();
  }

  private form: any = {
    // shop_name: '',
    time: [...getCurrentMonthDate()],
  };

  private pickerOptions: any = {
    // shortcuts: [
    //   // {
    //   //   text: '本月',
    //   //   onClick(picker: any) {

    //   //     picker.$emit('pick', getCurrentMonthDate());
    //   //   }
    //   // },
    //   {
    //     text: "上月",
    //     onClick(picker: any) {
    //       picker.$emit("pick", getLastMonthDate());
    //     },
    //   },
    // ],
  };

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private activated(): void {
    // if (this.$route.query.name) {
    //   this.form.shop_name = this.$route.query.name;
    // }
    this.search();
  }

  private getTable(): void {
    this.dayDataStatisticApi.dayDataStatisticBusiness(
      {
        ...this.getMonthIncomeAuditTableListParams(),
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
      }
    );
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  // 商户字段名 文案对应关系
  private monthIncomeExcelList2ch: any = {
    date: "日期",
    gmv: "GMV",
    user_num: "新增用户",
    activity: "日活",
    app_activity: "APP日活",
    ios_register: "IOS注册",
    android_register: "Android注册",
    platform_income: "平台抽成",
    shop_member_recharge: "单店卡充值",
    shop_member_consume: "单店卡消耗",
    nationwide_member_recharge: "全国会员充值",
    nationwide_member_consume: "全国会员消耗",
    meituan: "美团券",
    douyin: "抖音券",
    kuaishou: "快手券",
    changda: "优惠券",
    sport: "体育券",
    wechat_alipay: "微信/支付宝",
    sales_counter: "售卖柜",
    cashier_product_sales: "收银台商品",
    cashier_assistant: "收银台教练",
    user_assistant: "助教",
    open_duration: "开台时长（h）",
    free_open_duration: "免费开台时长（h）",
    order_num: "订单量",
    billiard_entry_fee: "报名费",
  };
  // 商户Excel 表头的先后顺序
  private monthIncomeExcelSortList: string[] = [
    "date",
    "gmv",
    "user_num",
    "activity",
    "app_activity",
    "ios_register",
    "android_register",
    "platform_income",
    "shop_member_recharge",
    "shop_member_consume",
    "nationwide_member_recharge",
    "nationwide_member_consume",
    "meituan",
    "douyin",
    "kuaishou",
    "changda",
    "sport",
    "wechat_alipay",
    "sales_counter",
    "cashier_product_sales",
    "cashier_assistant",
    "user_assistant",
    "open_duration",
    "free_open_duration",
    "order_num",
    "billiard_entry_fee",
  ];

  private getMonthIncomeAuditTableListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const form = this.form;

    const tablePage = this.tablePage;

    const {
      // shop_name, // 店铺名称

      time,
    } = form || {};

    // console.log(technicianItem, "technicianItem");

    const { pageNo, pageSize } = tablePage || {};

    const outerParams: any = {};

    // if (shop_name) {
    //   outerParams.shop_name = shop_name;
    // }

    if (Array.isArray(time) && time.length === 2) {
      const [start_date, end_date] = time;

      outerParams.start_date = start_date;

      outerParams.end_date = end_date;
    }

    if (
      mode == "exportExcel" &&
      !(outerParams.start_date && outerParams.end_date)
    ) {
      this.$message.error("请选择创建时段导出数据");
      return null;
    }

    if (mode === "all") {
      outerParams.page = pageNo;

      outerParams.limit = pageSize;
    } else if (mode == "exportExcel") {
      // outerParams.is_export = 1
    }

    console.log(outerParams, "outerParams");

    return outerParams;
  }

  // 点击导出excel 表格
  private handleExportExcelMonthIncomeAuditList() {
    this.getTable();
    const params: any = this.getMonthIncomeAuditTableListParams("exportExcel");

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.dayDataStatisticApi.exportExcelDayDataStatisticList(
      params,
      (res: any) => {
        const { start_date, end_date } = params || {};

        const filename = `每日数据统计营收列表 ${start_date}到${end_date}.xlsx`;
        const { data } = res || {};

        const { list: originList } = data || {};

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              newItem.orderNum =
                typeof orderNum == "number"
                  ? orderNum.toString()
                  : orderNum || "";

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const monthIncomeExcelSortList = this.monthIncomeExcelSortList;

          const monthIncomeExcelList2ch = this.monthIncomeExcelList2ch;

          const excelJSONData = getExcelJSONDataByList(
            newList,
            monthIncomeExcelSortList,
            monthIncomeExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }

        // const testData = [
        //   { 主页: "test1223424", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 主页: "433", 名称: "6800", 数量: "6800", 昵称: "广告主网" },
        //   { 名称: "22", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 名称: "43", 商家: "6800", 数量: "6800", 昵称: "广告主网", },
        //   { 店家: "43", 价格: "6800", 数量: "6800", 昵称: "广告主网", }
        // ]

        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }
}
